import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'core-js/web/url-search-params';
import 'custom-event-polyfill';
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import bootstrap from './bootstrap';
// eslint-disable-next-line @typescript-eslint/no-empty-function
bootstrap(() => {});
